import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import MerchantOfferAction from "./action";
import { columns } from "./columns.js";
import callApi from "../callApi";
import "./style.css";


const MerchantOfferTableView = () => {
    const navigate = useNavigate();
    const [allData, setAllData] = useState([]);
    const [paginationData, setPaginationData] = useState([]);
    const [pagination, setPagination] = useState({
        limit: 100,
        document_id: null,
        page: 0
    });
    const [responseMessage, setResponseMessage] = useState(
        "There are no records to display"
    );
    const [pending, setPending] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const [offer, setOffer] = useState({});

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [searchData, setSearchData] = useState({
        search_field: "",
        search_text: ""
    });
    const [searchClick, setSearchClick] = useState(false);

    const search_fields = [
        { value: 'source', label: 'Source' },
        { value: 'aggregator', label: 'Aggregator' },
        { value: 'title', label: 'Title' },
        { value: 'merchant_name', label: 'Merchant Name' },
        { value: 'flagged', label: 'Flagged' }
    ];

    const searchMerchantOffers = (searchData, setSubmitting) => {
        setPending(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/merchant_offers`;
        url = url + `?search_field=${searchData.search_field}&search_text=${searchData.search_text}`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.merchant_offers) {
                    setAllData(json.data.merchant_offers);
                }
                if (json.status && json.status.message) {
                    setResponseMessage(json.status.message);
                }
                setSearchClick(true);
                setPending(false);
                if (setSubmitting) {
                    setSubmitting(false);
                }
            })
            .catch((err) => err);
    };

    const onClear = (resetForm) => {
        setSearchClick(false);
        setSearchData({
            search_field: "",
            search_text: ""
        });
        resetForm();
        getMerchantOffersData(pagination);
    };

    useEffect(() => {
        getMerchantOffersData(pagination);
    }, [pagination])

    const getMerchantOffersData = (paginateData) => {
        setPending(true);
        let paginationLimit =
            paginateData && "limit" in paginateData ? paginateData.limit : 100;
        let paginationDocumentId =
            paginateData && "document_id" in paginateData ? paginateData.document_id : null;
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/merchant_offers?limit=${paginationLimit}`;
        if (paginationDocumentId) {
            url = url + `&document_id=${paginationDocumentId}`;
        }
        callApi(url)
            .then((json) => {
                if (json.data && json.data.merchant_offers) {
                    setAllData(json.data.merchant_offers);
                    let paginateData = _.cloneDeep(paginationData)
                    paginateData[pagination.page] = [
                        paginationDocumentId,
                        json.data.merchant_offers[json.data.merchant_offers.length - 1].id
                    ]
                    setPaginationData(paginateData)
                }
                if (json.status && json.status.message) {
                    setResponseMessage(json.status.message);
                }
                setPending(false);
            })
            .catch((err) => err);
    };

    const deleteMerchantOffer = () => {
        setButtonDisabled(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/merchant_offers`;
        url = url + `?document_ids=${offer.id}`
        callApi(url, 'DELETE')
            .then((json) => {
                setButtonDisabled(false);
                handleModalClose();
                searchClick ? searchMerchantOffers(searchData) : getMerchantOffersData(pagination)
            })
            .catch((err) => err);
    };

    const onNextClick = () => {
        setPagination({
            limit: 100,
            document_id: paginationData[pagination.page][1],
            page: pagination.page + 1,
        });
    };

    const onPreviousClick = () => {
        setPagination({
            limit: 100,
            document_id: paginationData[pagination.page - 1][0],
            page: pagination.page - 1
        });
    };

    const onAddClick = () => {
        navigate('/merchant-offers/add');
    }

    const downloadMerchantOffersData = () => {
        setPending(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/merchant_offers/download`;
        callApi(url, 'POST', {}, true)
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'merchant_offers.csv';
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                getMerchantOffersData();
            })
            .catch((err) => err);
    };

    return (
        <div className="merchant-offer-page">
            <h1 className="title">
                Merchant Offers
            </h1>
            <Formik
                initialValues={searchData}
                validate={values => {
                    const errors = {};
                    if (!values.search_field) {
                        errors.search_field = 'Required';
                    }
                    if (!values.search_text) {
                        errors.search_text = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSearchData(values);
                    setSubmitting(true);
                    searchMerchantOffers(values, setSubmitting);
                }}
                >
                {({ values, isSubmitting, setFieldValue, resetForm }) => (
                    <Form>
                        <div className="row mt-40">
                            <label className="col-md-2">Search Field:*</label>
                            <Select
                                className="col-md-8"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                name="search_field"
                                options={search_fields}
                                onChange={(selected) => {
                                    setFieldValue("search_field", selected.value)
                                    if (selected.value == 'flagged') {
                                        setFieldValue("search_text", true) 
                                    }
                                }} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="search_field" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Search Text:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="search_text" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="search_text" component="div" />
                        </div>
                        <div className="merchant-offer-container">
                            <button 
                                className="blue-button"
                                type="submit" disabled={isSubmitting}>
                                    Search
                            </button>
                            <button 
                                className="red-button"
                                type="button"
                                onClick={() => onClear(resetForm)}>
                                    Clear
                            </button>
                            <button 
                                className="red-button"
                                onClick={downloadMerchantOffersData}>
                                    Download
                            </button>
                            <button 
                                className="merchant-offer-button"
                                type="button"
                                onClick={onAddClick}>
                                    Add
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            <div className="mt-40">
                <DataTable
                    title={''}
                    columns={columns}
                    data={allData ? allData : []}
                    noDataComponent={responseMessage}
                    progressPending={pending}
                    onRowClicked={(row) => {
                        setOffer(_.cloneDeep(row));
                        handleModalShow();
                    }}
                />
            </div>
           {
                !searchClick ?
                    <div className="merchant-offer-container">
                        <button 
                            className="merchant-offer-button"
                            onClick={onPreviousClick}
                            disabled={pagination.page <= 0}>
                                Previous
                        </button>
                        <button 
                            className="merchant-offer-button"
                            onClick={onNextClick}>
                                Next
                        </button>
                    </div> : null
           }
            <Modal show={showModal} onHide={handleModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Selected Merchant Offer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MerchantOfferAction 
                        merchantOffer={offer}
                        handleModalClose={handleModalClose}
                        setButtonDisabled={setButtonDisabled}
                        updateMerchantOfferTable={() => {
                            searchClick ? searchMerchantOffers(searchData) : getMerchantOffersData(pagination)
                        }} />
                </Modal.Body>
                <Modal.Footer>
                {
                    // offer.source === 'dobin' ?
                    offer.source ?
                        <div>
                            <button
                                type="button"
                                className="red-button"
                                disabled={buttonDisabled}
                                onClick={() => deleteMerchantOffer()}>
                                    Delete
                            </button>
                            <button 
                                form="merchant-offer-edit"
                                type="submit"
                                className="blue-button"
                                disabled={buttonDisabled}>
                                    Save
                            </button>
                        </div>:
                        <div className="text-danger">
                            This is a READ ONLY merchant offer!
                        </div>
                }   
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MerchantOfferTableView;